import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'quaddiesTab',
  sx: {
    '&&': {
      marginLeft: '0',
      marginRight: '0',
      border: 'none',
    },
    py: '2',
    '&&[data-active="true"]': {
      bg: '#FFB000',
      borderBottom: 'none',
      h: '34px',
      '& > span': {
        borderRadius: '3px',
        padding: '1',
        bg: 'alpha.900',
        color: '#FFB000',
        fontWeight: '300',
      },
    },
    '&&[data-active]': {
      borderRadius: 'none',
      boxShadow: 'none',
    },
    '&.subTab': {
      color: 'gray.700',
    },
  },
};

export const BoxWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
  borderRadius: ['none', 'lg'],
  px: '4',
  mx: ['-2', '0'],
  mt: '1',
  mb: '0',
  h: 'fit-content',
  fontSize: 'sm',

  '&:has(+ .subTabBar)': {
    borderBottomRadius: 'none',
  },
  '&.tabBar': {
    bg: 'blackAlpha.400',
    boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
    borderRadius: ['none', 'lg'],
    h: 'fit-content',
    mt: 2,
    color: 'white',
    '&:has(+ .subTabBar)': {
      borderBottomRadius: 'none',
    },
  },
  '&.subTabBar': {
    bg: '#FFB000',
    color: '#442601',
    borderBottomRadius: ['none', 'lg'],
    borderTopRadius: 'none',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.10)',
    mt: 0,
    h: 'fit-content',
  },
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
  flex: '0 0 auto',
};

export const tickForToteMulti: CSSObject = {
  position: 'absolute',
  width: '11px',
  height: '11px',
  bg: '#FFC138',
  top: '-5px',
  right: '-5px',
  borderRadius: '3px',
  _before: {
    content: "' '",
    height: '6px',
    width: '2px',
    backgroundColor: 'alpha.800',
    position: 'absolute',
    top: '2px',
    right: '3px',
    transform: 'rotate(45deg)',
  },
  _after: {
    content: "' '",
    height: '2px',
    width: '4px',
    backgroundColor: 'alpha.800',
    position: 'absolute',
    top: '6px',
    right: '5px',
    transform: 'rotate(210deg)',
  },
};
